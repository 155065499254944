import { IBrandInfo } from '../models';

const extractor = (
  pageHref: string,
  { lang, brandName, siteUrl, sameAs, brandSchemaLogoUrl }: IBrandInfo
): object => {
  const url = new URL(pageHref);

  return {
    mainEntityOfPage: {
      '@type': 'WebPage',
      '@id': pageHref,
    },
    inLanguage: lang,
    author: {
      '@type': 'Organization',
      name: brandName,
    },
    publisher: {
      '@type': 'Organization',
      name: brandName,
      url: siteUrl,
      sameAs,
      logo: {
        '@type': 'ImageObject',
        url: brandSchemaLogoUrl || `${url.origin}/images/logo.png`,
      },
    },
  };
};

export default extractor;
